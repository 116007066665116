import { Text } from "@mantine/core"
import { useEffect, useState } from "react"

export const FormValidationMessage = ({message}) => {

    const [errorMessage, setMessage] = useState(null);
    
    useEffect(() => {
        if(message) {
            setMessage(message);
        }
    }, [message]);
    return(
        <>
            { errorMessage && (
                <Text sx={{ color: 'red', fontStyle: 'italic'}}> *{errorMessage} </Text>
            )}
        </>
    )
}