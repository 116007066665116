import { ArticleCreate } from "./entry/ArticleCreate";
import { ArticleTypeCreate } from "./entry/ArticleTypeCreate";
import { ArticleTypeUpdate } from "./entry/ArticleTypeUpdate";
import { ArticleUpdate } from "./entry/ArticleUpdate";
import { ArticleTable } from "./view/ArticleTable";
import { ArticleTypeTable } from "./view/ArticleTypeTable";

export const useArticleRoutes = [
    {
        path: "article/new",
        element: <ArticleCreate />
    },
    {
        path: "article",
        element: <ArticleTable />
    },
    {
        path: "article/:id",
        element: <ArticleUpdate />
    },
    {
        path: "article/type",
        element: <ArticleTypeTable />
    },
    {
        path: "article/type/new",
        element: <ArticleTypeCreate />
    },
    {
        path: "article/type/:id",
        element: <ArticleTypeUpdate />
    }
];