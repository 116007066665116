import { Button, Card, Divider, Grid, PasswordInput, Text, TextInput } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { postRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { useUserPayload } from "../useUserPayload";
import { endpoints } from "../../../config/endpoints";

export const UserCreate = () => {
    useDocumentTitle("New User");

    const [payload, setPayload] = useState(useUserPayload.create);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const submitCreateUser = async () => {
        setLoading(true);
        setErrors(null);

        const response = await postRequest(`${endpoints.user}`, payload);

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: User create",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "User create",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            return;
        }
    }

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section>
                        <Text sx={{ fontSize: 20, fontWeight: 500}}> Create User </Text>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter full name"
                                    label="Name"
                                    disabled={loading}
                                    error={errors && errors['full_name'] && (<FormValidationMessage message={errors['full_name'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'full_name', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />

                                <TextInput
                                    my={"sm"}
                                    type="email"
                                    placeholder="Enter email address"
                                    label="Email"
                                    disabled={loading}
                                    error={errors && errors['email'] && (<FormValidationMessage message={errors['email'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'email', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />

                                <PasswordInput 
                                    my={"sm"}
                                    placeholder="Enter password"
                                    label="Password"
                                    disabled={loading}
                                    error={errors && errors['password'] && (<FormValidationMessage message={errors['password'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'password', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter username"
                                    label="Username"
                                    disabled={loading}
                                    error={errors && errors['username'] && (<FormValidationMessage message={errors['username'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'username', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />

                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter phone number"
                                    label="Phone Number"
                                    disabled={loading}
                                    error={errors && errors['phone'] && (<FormValidationMessage message={errors['phone'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'phone', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />

                                <PasswordInput 
                                    my={"sm"}
                                    placeholder="Enter confirm password"
                                    label="Password"
                                    disabled={loading}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'password_confirmation', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12} px={10}>
                                <Button
                                    variant="outline" 
                                    loading={loading}
                                    onClick={() => submitCreateUser()}
                                >
                                    Save
                                </Button>
                            </Grid.Col>
                        </Grid>

                    </Card.Section>
                </Card>
            </Grid.Col>
        </Grid>
    )
}