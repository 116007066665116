import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { DefaultLayout } from "./layout/DefaultLayout";
import { BlankLayout } from "./layout/BlankLayout";
import { Login } from "./modules/auth/entry/Login";
import { PageNotFound } from "./layout/PageNotFound";
import { Forbidden } from "./layout/Forbidden";
import { useUserRoutes } from "./modules/user/userUserRoute";
import { useFaqRoutes } from "./modules/faq/useFaqRoutes";
import { useTourPackageRoutes } from "./modules/tourPackage/useTourPackageRoutes";
import { useInterestRoutes } from "./modules/interest/useInterestRoutes";
import { useContactRoutes } from "./modules/contact/useContactRoutes";
import { useDestinationRoutes } from "./modules/destination/useDestinationRoutes";
import { useArticleRoutes } from "./modules/article/useArticleRoutes";
import { useRegionRoutes } from "./modules/region/useRegionRoutes";

export const routers = createBrowserRouter([
  {
    path: "",
    element: <DefaultLayout />,
    children: [
      ...useUserRoutes,
      ...useFaqRoutes,
      ...useTourPackageRoutes,
      ...useInterestRoutes,
      ...useContactRoutes,
      ...useDestinationRoutes,
      ...useArticleRoutes,
      ...useRegionRoutes,
      ...useTourPackageRoutes
    ],
  },
  {
    path: "auth",
    element: <BlankLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
  {
    path: "pageNotFound",
    element: <PageNotFound />,
  },
  {
    path: "unauthorize",
    element: <Forbidden />,
  },
]);
