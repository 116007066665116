import { Button, Card, Divider, Grid, Group, Modal, Select, Text, TextInput } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useCallback, useEffect, useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { delRequest, getReqeust, putRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { useDestinationTypePayloads } from "../useDestinationTypePayloads";
import { endpoints } from "../../../config/endpoints";
import { useNavigate, useParams } from "react-router-dom";

export const DestinationTypeUpdate = () => {
    useDocumentTitle("Update Destination Type");

    const [payload, setPayload] = useState(useDestinationTypePayloads.update);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const delDestinationType = async () => {
        setLoading(true);
        setErrors(null);

        const response = await delRequest(`${endpoints.destinationType}/${params.id}`);

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Destination Type Delete",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Destination Type Delete",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            navigate(`/${endpoints.destinationType}`);
            return;
        }

        setLoading(false);
    }


    const submitUpdateDestinationType = async () => {
        setLoading(true);
        setErrors(null);

        const response = await putRequest(`${endpoints.destinationType}/${params.id}`, payload);

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Destination Type Update",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Destination Type Update",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            return;
        }

        setLoading(false);
    }

    /**
     * Loading Destination Type
     */
    const loadingData = useCallback(async() => {
        setLoading(true);
        setErrors(null);

        const response = await getReqeust(`${endpoints.destinationType}/${params.id}`);

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Destination Detail",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            setPayload(response.data);
            setLoading(false);
            return;
        }

        return false;

    },[dispatch, setPayload, params]);

    useEffect(() => {
        loadingData();
    },[loadingData]);

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section  px={"sm"}>
                        <Text sx={{ fontSize: 20, fontWeight: 500}}> Update Destination Type </Text>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={4} lg={4} px={"sm"} mt={"sm"}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter name"
                                    label="Name"
                                    disabled={loading}
                                    value={payload.name}
                                    error={errors && errors['name'] && (<FormValidationMessage message={errors['name'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'name', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={4} lg={4} px={'sm'} mt={"sm"}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter Remark"
                                    label="Remark"
                                    disabled={loading}
                                    value={payload.remark || ""}
                                    error={errors && errors['remark'] && (<FormValidationMessage message={errors['remark'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'remark', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={4} lg={4} px={'sm'} mt={"sm"}>
                                <Select
                                    my={"sm"}
                                    label="Status"
                                    disabled={loading}
                                    value={payload.status}
                                    data={[
                                        { value: "ACTIVE", label: "ACTIVE" },
                                        { value: "DISABLE", label: "DISABLE" }
                                    ]}
                                    error={errors && errors['status'] && <FormValidationMessage message={errors['status'][0]}/>}
                                    onChange={(e) => payloadHandler(payload, e, 'status', (updatePayload) => {
                                        setPayload(updatePayload);
                                        if(errors) {
                                            setErrors(null);
                                        }
                                    })}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={4} lg={4} px={'sm'} mt={"sm"}>
                                <Group>
                                    <Button
                                        variant="outline" 
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => submitUpdateDestinationType()}
                                    >
                                        Save
                                    </Button>

                                    { payload.destination === null && (
                                    <Button
                                        variant="outline" 
                                        color="red"
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        Delete
                                    </Button>
                                    )}
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Card.Section>
                </Card>
            </Grid.Col>

            <Modal 
                opened={isModalOpen} 
                withCloseButton 
                onClose={() => setIsModalOpen(false)} 
                title="Delete"
            >
                <Modal.Body> 
                    <p> Are you sure to delete this content?  </p>
                    <Button variant="outline" color="red" onClick={() => delDestinationType()}> OK </Button>
                </Modal.Body>
            </Modal>
        </Grid>
    )
}