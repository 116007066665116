export const useFaqPayload = {
    create: {
        question: "",
        answer: "",
    },
    update: {
        question: "",
        answer: "",
        status: null
    },
}