import { Badge } from "@mantine/core";
import { useEffect, useState } from "react";

const themes = [
    { status: "ACTIVE", color: 'green' },
    { status: "PENDING", color: 'yellow' },
    { status: "DISABLE", color: 'gray' },
    { status: "BLOCK", color: 'red' },
    { status: "DEFAULT", color: "green"}
];

export const StatusBadge = (props) => {

    const { status } = props;
    const [theme, setTheme ] = useState(themes[0]);


    useEffect(() => {
        if(status) {
            setTheme(themes.filter(value => value.status === status)[0]);
        }
    },[status]);


    return(
        <Badge variant="outline" size="sm" color={theme.color}> { theme.status } </Badge>
    )
}
