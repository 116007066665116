export const useArticleTypePayloads = {
    create : {
        name: "",
        description: "",
    },
    update: {
        name: "",
        description: "",
        status: ""
    }
}