import {
    Flex,
    Grid,
    PasswordInput,
    TextInput,
    Text,
    Card,
    Image,
    Group,
} from "@mantine/core";
import { useDocumentTitle, useLocalStorage} from "@mantine/hooks";
import { IconMail } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { postRequest } from "../../../services/apiService";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import logoImage from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../config/endpoints";
import { margin, padding, title } from "../../../config/layout";
import { primary, light } from "../../../config/color";
import { PrimaryBtn } from "../../../components/PrimaryBtn";
import { payloadHandler } from "../../../helpers/payloadHandler";

export const Login = () => {
    useDocumentTitle("Login");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [token, setToken] = useLocalStorage({key: 'token', defaultValue: null});
    const [user, setUser] = useLocalStorage({key: 'user', defaultValue: null});

    const [payload, setPayload] = useState({
        username: '',
        password: ''
    });

    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const submitLogin = async () => {
        setLoading(true);
        setErrors(null);

        const response = await postRequest(`${endpoints.login}`, payload);
        
        if(response && response.status === 200) {
            setToken(response.data.access_token);
            setUser(response.data.user);
            dispatch(updateNotification({
                title: "Login Success",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            return;
        }

        if(response && response.errors && response.errors.message) {
            dispatch(updateNotification({
                title: "Login Fail",
                message: response.errors.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        dispatch(updateNotification({
            title: "Login Fail",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    }


    useEffect(() => {
        if(token && user) {
            navigate("/region");
        }
    });

    return (
        <Grid gutter={0}>
            <Grid.Col sm={12} md={6} lg={6} p={padding} my={margin}>
                <Card sx={{ borderRight: `solid 3px ${primary}`, width: '100%'}}>
                    <Card.Section p={padding}>
                        <Group>
                            <Image
                                width={80}
                                height={80}
                                src={logoImage}
                            />

                            <Flex
                                direction={"column"}
                                align={"flex-start"}
                                justify={"flex-start"}
                            >
                                <Text fz={title} fw={800}> Pinlon Travel & Tour </Text>
                                <Text> your sologram</Text>
                            </Flex>
                        </Group>
                    </Card.Section>

                    <Card.Section p={padding}>
                        <Image
                            withPlaceholder
                            w={"100%"}
                            height={"300px"}
                            src="https://source.unsplash.com/random?travel"
                        />
                    </Card.Section>
                </Card>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={6} p={padding} my={margin}>
                <Flex
                    direction={"column"}
                    align={"center"}
                    justify={"center"}
                >
                    <Text fz={title} fw={800}> Administrator Login </Text>

                    <TextInput 
                        my={margin}
                        w={{base: "300px"}}
                        label="Email Address"
                        placeholder="Enter your email address"
                        icon={<IconMail />}
                        onChange={(e) => payloadHandler(payload, e.target.value, 'username', (updateValue) => {
                            setPayload(updateValue);
                        })}
                        error={errors && errors['username'] && (
                            <FormValidationMessage message={errors['username'][0]} />
                        )}
                    />

                    <PasswordInput 
                        my={margin}
                        w={{base: "300px"}}
                        label="Password"
                        placeholder="Enter your password"
                        onChange={(e) => payloadHandler(payload, e.target.value, 'password', (updateValue) => {
                            setPayload(updateValue);
                        })}
                        error={errors && errors['username'] && (
                            <FormValidationMessage message={errors['username'][0]} />
                        )}
                    />

                    <div style={{width: "300px"}}>
                        <PrimaryBtn
                            loading={loading}
                            submit={() => submitLogin()}
                            label={"Login"}
                        />
                    </div>

                    <Text 
                        fz={"sm"} 
                        color={light} 
                        p={padding} 
                        m={margin}
                    > 
                        Developed By InnoScript Co., Ltd 
                    </Text>
                </Flex>
            </Grid.Col>
        </Grid>
    );
};
