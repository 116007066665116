import { Button, Card, Divider, Flex, Grid, Select, Text, TextInput, Textarea } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useCallback, useEffect, useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { delRequest, getReqeust, putRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { useFaqPayload } from "../useFaqPayload";
import { endpoints } from "../../../config/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { ActionButtons } from "../../../components/ActionButtons";

export const UpdateFaq = () => {
    useDocumentTitle("Update FAQ");

    const [payload, setPayload] = useState(useFaqPayload.create);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const delFaq = async () => {
        setLoading(true);
        setErrors(null);

        const response = await delRequest(`${endpoints.faq}/${params.id}`);

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Faq delete",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Faq delete",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            navigate(`/${endpoints.faq}`);
            return;
        }

        setLoading(false);
    }


    const submitUpdateFaq = async () => {
        setLoading(true);
        setErrors(null);

        const response = await putRequest(`${endpoints.faq}/${params.id}`, payload);

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Faq Update",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Faq Update",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            return;
        }

        setLoading(false);
    }

    const loadingData = useCallback(async () => {
        setLoading(true);
        setErrors(null);

        const response = await getReqeust(`${endpoints.faq}/${params.id}`);

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Faq Detail",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            setPayload(response.data);
            setLoading(false);
            return;
        }

        return false;
    }, [dispatch, params]);

    useEffect(() => {
        loadingData();
    },[loadingData]);

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section>
                        <Flex
                            direction={"row"}
                            align={"flex-end"}
                            justify={"space-between"}
                        >
                            <Text sx={{ fontSize: 20, fontWeight: 500}}> FAQs </Text>

                            <ActionButtons 
                                deltitle="Delete FAQ"
                                del={true}
                                delSubmit={() => delFaq()}
                            />
                        </Flex>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter question"
                                    label="Name"
                                    disabled={loading}
                                    value={payload.question}
                                    error={errors && errors['question'] && (<FormValidationMessage message={errors['question'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'question', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />

                                <Textarea 
                                    my={"sm"}
                                    placeholder="Enter answer"
                                    label="Answer"
                                    disabled={loading}
                                    value={payload.answer}
                                    error={errors && errors['answer'] && (<FormValidationMessage message={errors['answer'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'answer', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />

                                <Select
                                    my={"sm"}
                                    label="Status"
                                    disabled={loading}
                                    value={payload.status}
                                    data={[
                                        { value: "ACTIVE", label: "ACTIVE" },
                                        { value: "DISABLE", label: "DISABLE" }
                                    ]}
                                    error={errors && errors['status'] && <FormValidationMessage message={errors['status'][0]}/>}
                                    onChange={(e) => payloadHandler(payload, e, 'status', (updatePayload) => {
                                        setPayload(updatePayload);
                                        if(errors) {
                                            setErrors(null);
                                        }
                                    })}
                                />

                                <Button
                                    variant="outline" 
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() => submitUpdateFaq()}
                                >
                                    Save
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </Card.Section>
                </Card>
            </Grid.Col>
        </Grid>
    )
}