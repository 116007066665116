import { Button, Flex, TextInput } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import { useState } from "react"
import { margin } from "../config/layout";

export const Search = (props) => {

    const { description, submit, loading } = props;

    const [keyowrd, setKeyword] = useState("");

    return(
        <Flex 
            my={margin}
            direction={"row"}
            justify={"flex-start"}
            align={"flex-end"}
        >
            <TextInput 
                icon={<IconSearch />}
                description={description}
                placeholder="Search"
                disabled={loading}
                value={keyowrd}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyUp={(e) => {
                    if(e.key === 'Enter') {
                        submit(keyowrd);
                    }
                }}
            />

            <Button
                ml={margin}
                variant="outline"
                disabled={loading}
                onClick={() => {
                    setKeyword("");
                    submit("")
                }}
            >
                Reset
            </Button>
        </Flex>
    )
}