export const endpoints = {
    login: "auth/login",
    user: "user",
    faq: "faq",
    tourPackage: "tour-package",
    interest: "interest",
    contact: "contact",
    destinationType: "destination/type",
    destination: "destination",
    article: "article",
    articleType: "article/type",
    region: "region",
}