import { Button, Card, Divider, Grid, Text, TextInput, Select, FileInput, Image, Modal, Group } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useCallback, useEffect, useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { delRequest, getReqeust, postRequest, putRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { useArticlePayloads } from "../useArticlePayloads";
import { endpoints } from "../../../config/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, IMAGE_URL } from "../../../config/environment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const ArticleUpdate = () => {
    useDocumentTitle("Article Update");

    const [payload, setPayload] = useState(useArticlePayloads.update);
    const [articleType, setArticleType] = useState([]);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    /** Upload Cover Photo */
    const uploadFileHandler = async (e, field) => {
        setLoading(true);
        setErrors(null);
    
        const formData = new FormData();
        formData.append('file', e);
        formData.append('method', 'PUT');
    
        const response = await postRequest("file/upload", formData);
    
        setLoading(true);
        setErrors(null);
    
        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Upload image process",
                message: response.message,
                status: 'success'
            }));
    
            payloadHandler(payload, response.data.name, field, (updateValue) => {
                setPayload(updateValue);
                setLoading(false);
            });
            return;
        }
    
        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }
    
        dispatch(updateNotification({
            title: "Error: Upload image process",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    }

    /**
     * @returns
     * Create New Article
     */
    const submitArticleUpdate = async () => {
        let requestPayload = payload;
        requestPayload.content = content;
        const response = await putRequest(`${endpoints.article}/${params.id}`, requestPayload);

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Create new article",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            return;
        }

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        dispatch(updateNotification({
            title: "Error: Article create",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    }

    /**
     * Article Delete
     * @returns 
     */
    const delArticle = async () => {
        const response = await delRequest(`${endpoints.article}/${params.id}`);   

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Article Type List",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Delete Process",
                message: response.message,
                status: 'success'
            }));  
            setIsModalOpen(false);
            navigate("/article");
        }

        return;
    }

    /**
     * Loading Init Data
     */
    const loadingData = useCallback(async () => {
        setLoading(true);
        setErrors(null); 

        const response = await getReqeust(`${endpoints.articleType}`);

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Article Type List",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            const articleTypeArray = response.data.map((value) => {
                return {
                    value: value.id,
                    label: value.name
                }
            });
            setArticleType(articleTypeArray);
            setLoading(false);
        }

        const articleResponse = await getReqeust(`${endpoints.article}/${params.id}`);

        if(articleResponse && (articleResponse.status === 500 || articleResponse.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Article Detail",
                message: articleResponse.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(articleResponse && articleResponse.status === 200) {
            setPayload(articleResponse.data);
            setContent(articleResponse.data.content);
        }

        return false;
    },[dispatch, params]);

    /**
     * Watch
     */
    useEffect(() => {
        loadingData();
    },[loadingData])

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section px={"sm"}>
                        <Text sx={{ fontSize: 20, fontWeight: 500}}> Create New Article </Text>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Image 
                                className={`${payload.cover_photo ? "cover-image" : null}`}
                                src={payload.cover_photo ? `${IMAGE_URL}/${payload.cover_photo}` : null} 
                            />
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter Title"
                                    label="Title"
                                    disabled={loading}
                                    value={payload.title || ""}
                                    withAsterisk
                                    error={errors && errors['title'] && (<FormValidationMessage message={errors['title'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'title', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <Select
                                    my={"sm"}
                                    label="Article Type"
                                    disabled={loading}
                                    value={payload.article_type_id}
                                    data={articleType ? articleType : []}
                                    dropdownPosition={"bottom"}
                                    error={errors && errors['article_type_id'] && <FormValidationMessage message={errors['article_type_id'][0]}/>}
                                    onChange={(e) => payloadHandler(payload, e, 'article_type_id', (updatePayload) => {
                                        setPayload(updatePayload);
                                        if(errors) {
                                            setErrors(null);
                                        }
                                    })}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <FileInput 
                                    my={"sm"}
                                    label="Cover Photo"
                                    disabled={loading}
                                    accept="image/*"
                                    onChange={(e) => uploadFileHandler(e, "cover_photo")}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={content || ""}
                                    config={{
                                        ckfinder: {
                                            uploadUrl: `${BASE_URL}/api/ckfinder`
                                        }
                                    }}
                                    onChange={(event, editor) => {
                                        setContent(editor.getData());
                                    }}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                <Select
                                    my={"sm"}
                                    label="Status"
                                    disabled={loading}
                                    value={payload.status}
                                    data={[
                                        { value: "ACTIVE", label: "ACTIVE" },
                                        { value: "DISABLE", label: "DISABLE" }
                                    ]}
                                    error={errors && errors['status'] && <FormValidationMessage message={errors['status'][0]}/>}
                                    onChange={(e) => payloadHandler(payload, e, 'status', (updatePayload) => {
                                        setPayload(updatePayload);
                                        if(errors) {
                                            setErrors(null);
                                        }
                                    })}
                                />
                            </Grid.Col>
                        </Grid>
                        

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                <Group>
                                    <Button
                                        variant="outline" 
                                        loading={loading}
                                        onClick={() => submitArticleUpdate()}
                                    >
                                        Update
                                    </Button>

                                    <Button
                                        variant="outline"
                                        color="red"
                                        onClick={() => setIsModalOpen(true) }
                                    > 
                                        Delete 
                                    </Button>
                                </Group>
                            </Grid.Col>
                        </Grid>

                    </Card.Section>
                </Card>
            </Grid.Col>

            <Modal 
                opened={isModalOpen} 
                withCloseButton 
                onClose={() => setIsModalOpen(false)} 
                title="Delete"
            >
                <Modal.Body> 
                    <p> Are you sure to delete this content?  </p>
                    <Button variant="outline" color="red" onClick={() => delArticle()}> OK </Button>
                </Modal.Body>
            </Modal>
        </Grid>
    )
}