import { Button, Flex, Text } from '@mantine/core'
import React from 'react'
import { Link } from 'react-router-dom'

export const Forbidden = () => {
  return (
    <>
      <Flex justify={'center'} align={'center'} direction={'column'} h={'100%'}>
        <Text sx={{fontSize:"8em",fontFamily:"monospace"}}>403</Text>
        <Text sx={{fontSize:"2em",}}>Access denied...</Text>
        <Text>You don't have permission to access this page.</Text>
        <Link to={"/dashboard"}>
          <Button sx={{marginBlock:'0.5em'}}>Go To Dashboard</Button>
        </Link>
      </Flex>
    </>
  )
}
