import { InterestCreate } from "./entry/InterestCreate";
import { InterestUpdate } from "./entry/InterestUpdate";
import { InterstTable } from "./view/InterestTable";

export const useInterestRoutes = [
    {
        path: "interest/new",
        element: <InterestCreate />
    },
    {
        path: "interest",
        element: <InterstTable />
    },
    {
        path: "interest/:id",
        element: <InterestUpdate />
    }
];