import { Button, Card, Divider, Grid, Text, TextInput, Textarea } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { postRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { useFaqPayload } from "../useFaqPayload";
import { endpoints } from "../../../config/endpoints";
import { useNavigate } from "react-router-dom";

export const CreateFaq = () => {
    useDocumentTitle("New User");

    const [payload, setPayload] = useState(useFaqPayload.create);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitCreateUser = async () => {
        setLoading(true);
        setErrors(null);

        const response = await postRequest(`${endpoints.faq}`, payload);

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Faq create",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "User Faq",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            navigate(`/${endpoints.faq}`);
            return;
        }
    }

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section>
                        <Text sx={{ fontSize: 20, fontWeight: 500}}> Create FAQ </Text>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter question"
                                    label="Name"
                                    disabled={loading}
                                    withAsterisk
                                    error={errors && errors['question'] && (<FormValidationMessage message={errors['question'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'question', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={12} lg={12}>
                                <Textarea 
                                    my={"sm"}
                                    placeholder="Enter answer"
                                    label="Answer"
                                    disabled={loading}
                                    withAsterisk
                                    error={errors && errors['answer'] && (<FormValidationMessage message={errors['answer'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'answer', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12}>
                                <Button
                                    variant="outline" 
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() => submitCreateUser()}
                                >
                                    Save
                                </Button>
                            </Grid.Col>
                        </Grid>

                    </Card.Section>
                </Card>
            </Grid.Col>
        </Grid>
    )
}