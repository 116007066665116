import { Card, Divider, Flex, Grid, Text } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { NavLink } from "react-router-dom";
import {DataTable} from "mantine-datatable";
import { useCallback, useEffect, useState } from "react";
import { getReqeust } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { minHeight, paginationSize, recordsPerPageOptions } from "../../../config/datatable";
import { endpoints } from "../../../config/endpoints";
import { margin } from "../../../config/layout";
import { Search } from "../../../components/Search";
import { ActionButtons } from "../../../components/ActionButtons";
import { StatusBadge } from "../../../components/StatusBadge";
import { useRegionParams } from "../useRegionParams";

export const RegionList = () => {
    useDocumentTitle("Region List");

    const [loading, setLoading] = useState(false);
    const [records, setRecord] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState(useRegionParams);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'id', direction: 'asc' });
 
    const dispatch = useDispatch();

    const columns = [
        { accessor: "title", title: 'Title', sortable: true, render: (row) => {
            return(
                <NavLink
                    sx={{ color: "blue", textDecoration: 'underline'}}
                    to={`${row.id}`}
                >
                {row.title}
            </NavLink>
            )
        }},
        { accessor: 'sub_title', title: "SubTitle", sortable: true },
        { accessor: 'status', title: 'Status', sortable: true, render: ({status}) => {
            return (
                <StatusBadge status={status} />
            )
        } }
    ];

    const sortStatusHandler = (e) => {
        let updateSortStatus = {...sortStatus};
        updateSortStatus.columnAccessor = e.columnAccessor;
        updateSortStatus.direction = e.direction;
        setSortStatus(updateSortStatus);

        let updateParams = {...params};
        updateParams.order = sortStatus.columnAccessor;
        updateParams.sort = sortStatus.direction;
        setParams(updateParams);
    }

    const paginateHandler = (e, field) => {
        let updateParams = {...params};
        updateParams[field] = e;
        setParams(updateParams);
    }

    const searchHandler = (e) => {
        let updateParams = {...params};
        updateParams.search = e;
        updateParams.page = 1;
        setParams(updateParams);
    }

    const loadingData = useCallback(async () => {
        setLoading(true);
        const response = await getReqeust(endpoints.region, params);

        if(response && response.status === 200) {
            setRecord(response.data.data);
            setTotal(response.data.total);
            setLoading(false);
            return;
        }

        dispatch(updateNotification({
            title: "Error: Retrived destination type record",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;

    }, [dispatch, params]);

    useEffect(() => {
        if(params) {
            loadingData();
        }
    }, [params, loadingData]);

    return(
        <Grid gutter={0}>
            <Grid.Col>
                <Card p={50} className="card-border">
                    <Card.Section my={margin}>
                        <Flex
                            direction={"row"}
                            align={"flex-end"}
                            justify={"space-between"}
                        >
                            <Text sx={{ fontSize: 20, fontWeight: 500}}> Destination List </Text>

                            <ActionButtons 
                                create={true}
                            />
                        </Flex>
                        <Divider my={margin} variant="dashed" />
                    </Card.Section>

                    <Card.Section my={margin}>
                        <Search 
                            loading={loading}
                            description={useRegionParams.columns}
                            submit={(e) => searchHandler(e)}
                        />

                        <DataTable 
                            minHeight={minHeight}
                            striped
                            highlightOnHover
                            records={records}
                            columns={columns}
                            sortStatus={sortStatus}
                            totalRecords={total}
                            recordsPerPage={params.per_page}
                            page={params.page}
                            paginationSize={paginationSize}
                            recordsPerPageOptions={recordsPerPageOptions}
                            onSortStatusChange={(e) => sortStatusHandler(e)}
                            onRecordsPerPageChange={(e) => paginateHandler(e, 'per_page')}
                            onPageChange={(e) => paginateHandler(e, 'page')}
                        />
                    </Card.Section>
                </Card>
            </Grid.Col>
        </Grid>
    )
}