import { Button, Card, Divider, Grid, Text, TextInput } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { postRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { useInterestPayloads } from "../useInterestPayloads";
import { endpoints } from "../../../config/endpoints";
import { useNavigate } from "react-router-dom";

export const InterestCreate = () => {
    useDocumentTitle("New Interest");

    const [payload, setPayload] = useState(useInterestPayloads.create);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     * Create New Interest
     */
    const submitInterstCreate = async () => {
        const response = await postRequest(`${endpoints.interest}`, payload);

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Create new insterest",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            navigate("/interest");
            return;
        }

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        dispatch(updateNotification({
            title: "Error: User create",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    }

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section px={"sm"}>
                        <Text sx={{ fontSize: 20, fontWeight: 500}}> Create New Interest </Text>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter Interest Name"
                                    label="Name"
                                    disabled={loading}
                                    withAsterisk
                                    error={errors && errors['name'] && (<FormValidationMessage message={errors['name'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'name', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter Remark"
                                    label="Remark"
                                    disabled={loading}
                                    error={errors && errors['remark'] && (<FormValidationMessage message={errors['remark'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'title', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                <Button
                                    variant="outline" 
                                    loading={loading}
                                    onClick={() => submitInterstCreate()}
                                >
                                    Save
                                </Button>
                            </Grid.Col>
                        </Grid>

                    </Card.Section>
                </Card>
            </Grid.Col>
        </Grid>
    )
}