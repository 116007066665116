import { AppShell, Container, MantineProvider } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { Outlet } from 'react-router-dom';
import { DefaultNavigation } from './components/DefaultNavigation';
import { AppNotification } from "../components/AppNotification";

export const DefaultLayout = () => {

    const [schema] = useLocalStorage({defaultValue: null, key: "color-schema"});

    return(
        <MantineProvider 
            withGlobalStyles 
            withNormalizeCSS 
            theme={{
                colorScheme: schema ? schema : "light"
            }}
        >
            <AppNotification />

            <Container fluid mx={0} px={0}>
                <AppShell 
                    navbar={<DefaultNavigation />}
                    bg={"#f2f2f2"}
                >
                    <Outlet />
                </AppShell>
            </Container>
        </MantineProvider>
    )
}