import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const drawerSlice = createSlice({
    name: "DRAWER",
    initialState,
    reducers: {
        drawerOpen: (state, action) => {
            state = action.payload;
            return state;
        }
    }
});

export const { drawerOpen } = drawerSlice.actions;
export const drawerState = (state) => state.DRAWER;
export default drawerSlice.reducer;