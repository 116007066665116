import { DestinationCreate } from "./entry/DestinationCreate";
import { DestinationTypeCreate } from "./entry/DestinationTypeCreate";
import { DestinationTypeUpdate } from "./entry/DestinationTypeUpdate";
import { DestinationUpdate } from "./entry/DestinationUpdate";
import { DestinationList } from "./view/DestinationList";
import { DestinationTypeList } from "./view/DestinationTypeList";

export const useDestinationRoutes = [
    {
        path: "destination/type/",
        element: <DestinationTypeList />
    },
    {
        path: "destination/type/new",
        element: <DestinationTypeCreate />
    },
    {
        path: "destination/type/:id",
        element: <DestinationTypeUpdate />
    },
    {
        path: "destination",
        element: <DestinationList />
    },
    {
        path: "destination/new",
        element: <DestinationCreate />
    },
    {
        path: "destination/:id",
        element: <DestinationUpdate />
    },
];