export const useDestinationTypePayloads = {
    create : {
        name: "",
        remark: ""
    },
    update: {
        name: "",
        remark: "",
        status: ""
    }
}