import { Card, Divider, PasswordInput, Text } from "@mantine/core"
import { useEffect, useState } from "react";
import { putRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { SaveButton } from "../../../components/SaveButton";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { useUserPayload } from "../useUserPayload";
import { endpoints } from "../../../config/endpoints";
import { useParams } from "react-router-dom";

export const UserChangePassword = ({ dataSource, update }) => {

    const [payload, setPayload] = useState(useUserPayload.update);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const params = useParams();

    const changePassword = async () => {
        setLoading(true);
        setErrors(null);
        const response = await putRequest(`${endpoints.user}/${params.id}/password`, payload);

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        if(response && (response.status === 401 || response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Password Changed",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Password Change",
                message: response.message,
                status: 'success'
            }));
            update(response.data);
            setLoading(false);
            return;
        }
    }

    useEffect(() => {
        if(dataSource) {
            setPayload(dataSource);
        }
    },[dataSource]);

    return(
        <Card p={20} className="card-border">
            <Card.Section my={20}>
                <Text sx={{ fontSize: 20, fontWeight: 500}}> Change Password </Text>    
                <Divider variant="dashed" my={10} />
            </Card.Section>

            <Card.Section px={10}>

                <PasswordInput
                    my={10}
                    placeholder="Enter new password"
                    label="New Password"
                    disabled={loading}
                    value={payload.password}
                    error={errors && errors['password'] && <FormValidationMessage message={errors['password'][0]}/>}
                    onChange={(e) => payloadHandler(payload, e.target.value, 'password', (updatePayload) => {
                        setPayload(updatePayload);
                        if(errors) {
                            setErrors(null);
                        }
                    })}
                />

                <PasswordInput
                    my={10}
                    placeholder="Enter confirmation password"
                    label="Confirm Password"
                    disabled={loading}
                    value={payload.password}
                    error={errors && errors['confirmation_password'] && <FormValidationMessage message={errors['confirmation_password'][0]}/>}
                    onChange={(e) => payloadHandler(payload, e.target.value, 'confirmation_password', (updatePayload) => {
                        setPayload(updatePayload);
                        if(errors) {
                            setErrors(null);
                        }
                    })}
                />

                <SaveButton 
                    loading={loading}
                    submit={() => changePassword()}
                />
            </Card.Section>
        </Card>
    )
}