import { createSlice } from "@reduxjs/toolkit";

const initialState =  [];

const purchaseItemsSlice = createSlice({
    name: "PURCHASE_ITEMS",
    initialState,
    reducers: {
        addToPurchaseItems( state , action) {
            const foundItem = state.find(item => item.id === action.payload.id)
            if (foundItem) {
                Object.assign(foundItem,action.payload);
                return ;
            }
            return [...state,action.payload];
        },
        removeFromPurchaseItems(state, action) {
            return state.filter(item => item.id !== action.payload);
        },
        removeAllFromPurchseItems() {
            return [];
        },
    }    
})

export const {
    addToPurchaseItems,
    removeFromPurchaseItems,
    removeAllFromPurchseItems,
} = purchaseItemsSlice.actions;

export default purchaseItemsSlice.reducer;