import { UserCreate } from "./entry/UserCreate";
import { UserDetail } from "./view/UserDetail";
import { UserList } from "./view/UserList";

export const useUserRoutes = [
    {
        path: "user",
        element: <UserList />
    },
    {
        path: "user/new",
        element: <UserCreate />
    },
    {
        path: "user/:id",
        element: <UserDetail />
    }
];