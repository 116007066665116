import { Button, Checkbox, Group, Modal, Radio, Text, Tooltip } from "@mantine/core"
import { IconFileExport, IconPlus } from "@tabler/icons-react"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReqeust } from "../services/apiService";
import { updateNotification } from "../redux/notificationSlice";
import { useDispatch } from "react-redux";
import { IconTrashFilled } from "@tabler/icons-react";

export const ActionButtons = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const auditColumns = ["status", "created_at", "created_by", "updated_at", "updated_by", "deleted_at", "deleted_by"];

    const { create, fileExport, exportTitle, exportColumns, exportUrl, del, delSubmit, delTitle } = props;

    const [exportOpen, setExportOpen] = useState(false);
    const [delOpen, setDelOpen] = useState(false);
    const [selectableExportColumns, setExportCoumns] = useState([]);
    const [columnPayload, setColumnPayload] = useState([]);
    const [auditPayload, setAuditPayload] = useState(auditColumns)
    const [fileType, setFileType] = useState("excel");

    const exportHandler = async () => {
        const exportOptions = {
            columns : auditPayload.length > 0 ? `${columnPayload.toString()},${auditPayload}` : columnPayload.toString(),
            type: fileType
        };
        
        const response = await getReqeust(exportUrl, exportOptions);

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Export File",
                message: response.message,
                status: 'success'
            }));
            return;
        }

        dispatch(updateNotification({
            title: "Error: Export Fail",
            message: response.message,
            status: 'fail'
        })); 
        return;
    }

    useEffect(() => {
        if(exportColumns) {
            const getColumns = exportColumns.split(',');
            setExportCoumns(getColumns);
            setColumnPayload(getColumns);
        }
    },[exportColumns]);

    return(
        <Group mt={{ sm: "sm"}}>
            { create && (
                <Tooltip label="Create">
                    <Button size="small" variant="outline" color="green" onClick={() => navigate("new")} > 
                        <IconPlus />
                    </Button>
                </Tooltip>
            )}

            { fileExport && (
                <Tooltip label="Export">
                    <Button size="small" variant="outline" onClick={() => setExportOpen(true)} > 
                        <IconFileExport />
                    </Button>
                </Tooltip>
            )}

        
            { del && (
                <Tooltip label="Trash Inbox">
                    <Button size="small" variant="outline" color="red" onClick={() => setDelOpen(true)} > 
                        <IconTrashFilled />
                    </Button>
                </Tooltip>
            )}

        <Modal opened={exportOpen} onClose={() => setExportOpen(false)} title={exportTitle} size="auto">
            <Checkbox.Group
                my={"sm"}
                label="Select columns"
                description="Data will with export selected columns"
                withAsterisk
                defaultValue={selectableExportColumns || []}
                onChange={(e) => setColumnPayload(e)}
            >
                <Group my={"sm"}>
                    {selectableExportColumns && selectableExportColumns.map((value, index) => {
                        return(
                            <Checkbox 
                                key={`export_columns_key_id_${index}`}
                                value={value}
                                label={value}
                            />
                        )
                    })}
                    
                </Group>
            </Checkbox.Group>

            <Checkbox.Group
                my={"sm"}
                label="Select audit columns"
                description="Data will with export selected audit columns"
                withAsterisk
                defaultValue={auditColumns || []}
                onChange={(e) => setAuditPayload(e)}
            >
                <Group my={"sm"}>
                    {auditColumns && auditColumns.map((value, index) => {
                        return(
                            <Checkbox 
                                key={`export_adudit_    columns_key_id_${index}`}
                                value={value}
                                label={value}
                            />
                        )
                    })}
                    
                </Group>
            </Checkbox.Group>

            <Radio.Group
                my={"sm"}
                name="Export file format"
                label="Select file format"
                description="Data will export selected file format"
                withAsterisk
                value={fileType}
                onChange={(e) => setFileType(e)}
            >
                <Group my={"sm"}>
                    <Radio label="Excel" value="excel" />
                    <Radio label="PDF" value="pdf" />
                </Group>
            </Radio.Group>

            <Group position="right" my={"sm"}>
                <Button variant="outline" color="primary" onClick={() => exportHandler()}> Export </Button>
                <Button variant="outline" color="red" onClick={() => setExportOpen(false)}> Cancel </Button>
            </Group>
        </Modal>

        <Modal opened={delOpen} onClose={() => setDelOpen(false)} title={delTitle} size="md">
            <Text> Are you sure to delete this content? </Text>
            <Group position="right" my={"sm"}>
                <Button variant="outline" color="primary" onClick={() => delSubmit()}> Confirm </Button>
                <Button variant="outline" color="red" onClick={() => setDelOpen(false)}> Cancel </Button>
            </Group>
        </Modal>
    </Group>
    )
}