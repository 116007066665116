import { IconUsers, IconHelp, IconMapPinHeart, IconMailCheck, IconArticle, IconLocation, IconRoute, IconPackage } from "@tabler/icons-react";
import { endpoints } from "../../config/endpoints";

export const navList = [
    { icon: <IconMapPinHeart />, label: "Interest", url: "/interest", children: [] },
    { icon: <IconLocation />, label: "Region", url: "/region", children: [] },
    { icon: <IconPackage />, label: "Tour Package", url: "/tour-package", children: [] },
    { icon: <IconMailCheck />, label: "Contact", url: "/contact", children: [] },
    { icon: <IconHelp />, label: "FAQ", url: "/faq", children: [] },
    { icon: <IconUsers />, label: "User", url: endpoints.user,  children: []},
    { icon: <IconArticle />, label: "Article", url: null, children: [
        { label: "Article Type", url: "/article/type", children: [] },
        { label: "Article", url: "/article", children: [] },
    ] },
    { icon: <IconRoute />, label: "Destination", url: null, children: [
        { label: "Destination Type", url: "/destination/type", children: [] },
        { label: "Destination", url: "/destination", children: [] },
    ] },
]