import {
  Divider,
  Flex,
  Group,
  Image,
  NavLink,
  Navbar,
  ScrollArea,
  Text,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { navList } from "./DefaultNavList";
import { IconCircle0Filled, IconLock } from "@tabler/icons-react";
import { hoverColor, fontColor, primary } from "../../config/color";
import logo from "../../assets/images/logo.png";
import { margin, padding } from "../../config/layout";

export const DefaultNavigation = () => {

    const navigate = useNavigate();

    return (
        <Navbar height="100vh" p={padding} width={{ base: 250 }} bg={primary}>
            <Navbar.Section>
                <Group>
                    <Image 
                        src={logo} 
                        withPlaceholder 
                        width={40}
                        height={40}
                    />

                    <Flex
                        direction={"column"}
                        align={"flex-start"}
                        justify={"flex-start"}
                    >
                        <Text fz={"sm"} color="#fff" fw={800}> Pinlon Travel & Tour </Text>
                        <Text fz={"sm"} color="#fff"> Your sologram </Text>
                    </Flex>
                </Group>

                <Divider variant="dashed" my={margin} />
            </Navbar.Section>   

            <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
                { navList.map((value, index) => {
                    return(
                        <NavLink
                            key={`parent_key_id_${index}`}
                            icon={value.icon}
                            label={value.label}
                            sx={{ 
                                color: fontColor,
                                ":hover": {
                                    background: hoverColor,
                                    color: fontColor
                                }
                            }}
                            onClick={() => {
                                if(value.url) {
                                  navigate(value.url)
                                }
                            }}
                        >
                            {value.children.length > 0 && value.children.map((childValue, childIndex) => {
                                return (
                                <NavLink 
                                    key={`${index}_${childIndex}`} 
                                    label={childValue.label} 
                                    icon={<IconCircle0Filled size={10} />}
                                    sx={{ 
                                        color: fontColor,
                                        ":hover": {
                                            background: hoverColor,
                                            color: fontColor
                                        }
                                    }}
                                    onClick={() => {
                                        if(childValue.url) {
                                            navigate(childValue.url)
                                        }
                                    }}
                                />
                                )
                            })}
                        </NavLink>
                    )
                })}

                <NavLink
                    key={"logout"}
                    icon={<IconLock />}
                    label={"Logout"}
                    sx={{ 
                        color: fontColor,
                            ":hover": {
                                background: hoverColor,
                                color: fontColor
                            }
                        }}
                    onClick={() => {
                        localStorage.removeItem("user");
                        localStorage.removeItem("token")
                        navigate("/auth/login");
                    }}
                > 
                    Logout 
                </NavLink>
            </Navbar.Section>
        </Navbar>
    );
};
