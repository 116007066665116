export const useArticlePayloads = {
    create : {
        title: "",
        article_type_id: "",
        cover_photo: "",
        content: ""
    },
    update: {
        title: "",
        article_type_id: "",
        cover_photo: "",
        content: "",
        status: ""
    }
}