export const useUserPayload = {
    create : {
        username: "",
        full_name: "",
        email: "",
        phone: "",
        password: ""
    },
    update: {
        username: "",
        full_name: "",
        email: "",
        phone: "",
        password: "",
        status: ""
    },
    changePassword: {
        password: "",
        confirmation_password: ""
    }
}