import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const loadingSlice = createSlice({
    name: "LOADING",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setLoading } = loadingSlice.actions;
export const drawerState = (state) => state.LOADING;
export default loadingSlice.reducer;