import { configureStore } from '@reduxjs/toolkit'
import notificationSlice from './notificationSlice'
import drawerSlice from './drawerSlice'
import purchaseItemsSlice from './purchaseItemsSlice'
import loadingSlice from './loadingSlice'
import purchaseOrderSlice from './purchaseOrderSlice'


export const store = configureStore({
    reducer: {
        notificaiton: notificationSlice,
        drawer: drawerSlice,
        purchaseItems: purchaseItemsSlice,
        loading: loadingSlice,
        purchase: purchaseOrderSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})