import { ContactDetail } from "./view/ContactDetail";
import { ContactList } from "./view/ContactList";

export const useContactRoutes = [
    {
        path: "contact",
        children: [
            {
                path: "",
                element: <ContactList />,
            },
            {
                path: ":id",
                element: <ContactDetail />
            }
        ],
    },
];
