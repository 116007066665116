import { Badge, Card, Divider, Flex, Select, Text, TextInput } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useEffect, useState } from "react";
import { putRequest } from "../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../redux/notificationSlice";
import { SaveButton } from "../../components/SaveButton";
import { payloadHandler } from "../../helpers/payloadHandler";
import { FormValidationMessage } from "../../components/FormValidationMessage";
import { useUserPayload } from "./useUserPayload";
import { DelButton } from "../../components/DelButton";
import { endpoints } from "../../config/endpoints";
import { useParams } from "react-router-dom";

export const UserUpdate = ({ dataSource, update }) => {
    useDocumentTitle("User Detail And Update");

    const [payload, setPayload] = useState(useUserPayload.update);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const params = useParams();

    const submitUpdateUser = async () => {
        setLoading(true);
        setErrors(null);
        const response = await putRequest(`${endpoints.user}/${payload.id}`, payload);

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        if(response && (response.status === 401 || response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "User Update",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Update",
                message: response.message,
                status: 'success'
            }));
            update(response.data);
            setLoading(false);
            return;
        }
    }

    useEffect(() => {
        if(dataSource) {
            setPayload(dataSource);
        }
    },[dataSource]);

    return(
        <Card p={20} className="card-border">
            <Card.Section my={20}>
                <Flex
                    direction={"row"}
                    justify={"space-between"}
                    align={"center"}
                >
                    <Text sx={{ fontSize: 20, fontWeight: 500}}> Edit User Information </Text>

                    <div>
                        <Badge color={payload.status === 'ACTIVE' ? 'green' : 'red'}> 
                            {payload.status}
                        </Badge>

                        <DelButton 
                            title="Are you sure to delete?"
                            message="Do you want to delete this user?"
                            action={`${endpoints.user}/${params.id}`}
                            callbackUrl={`/${endpoints.user}`}
                        />
                    </div>
                </Flex>
                
                <Divider variant="dashed" my={10} />
            </Card.Section>

            <Card.Section px={10}>
                <TextInput 
                    my={10}
                    placeholder="Enter full name"
                    label="Name"
                    disabled={loading}
                    value={payload.full_name}
                    error={errors && errors['full_name'] && <FormValidationMessage message={errors['full_name'][0]}/>}
                    onChange={(e) => payloadHandler(payload, e.target.value, 'full_name', (updatePayload) => {
                        setPayload(updatePayload);
                        if(errors) {
                            setErrors(null);
                        }
                    })}
                />

                <TextInput 
                    my={10}
                    placeholder="Enter username"
                    label="Username"
                    disabled={loading}
                    value={payload.username}
                    error={errors && errors['username'] && <FormValidationMessage message={errors['username'][0]}/>}
                    onChange={(e) => payloadHandler(payload, e.target.value, 'username', (updatePayload) => {
                        setPayload(updatePayload);
                        if(errors) {
                            setErrors(null);
                        }
                    })}
                />

                <TextInput
                    my={10}
                    type="email"
                    placeholder="Enter email address"
                    label="Email"
                    disabled={loading}
                    value={payload.email}
                    error={errors && errors['email'] && <FormValidationMessage message={errors['email'][0]}/>}
                    onChange={(e) => payloadHandler(payload, e.target.value, 'email', (updatePayload) => {
                        setPayload(updatePayload);
                        if(errors) {
                            setErrors(null);
                        }
                    })}
                />

                <TextInput 
                    my={10}
                    placeholder="Enter phone number"
                    label="Phone Number"
                    disabled={loading}
                    value={payload.phone}
                    error={errors && errors['phone'] && <FormValidationMessage message={errors['phone'][0]}/>}
                    onChange={(e) => payloadHandler(payload, e.target.value, 'phone', (updatePayload) => {
                        setPayload(updatePayload);
                        if(errors) {
                            setErrors(null);
                        }
                    })}
                />

                <Select
                    my={10}
                    label="Status"
                    disabled={loading}
                    value={payload.status}
                    data={[
                        { value: "ACTIVE", label: "ACTIVE" },
                        { value: "PENDING", label: "PENDING" },
                        { value: "DISABLE", label: "DISABLE" }
                    ]}
                    error={errors && errors['status'] && <FormValidationMessage message={errors['status'][0]}/>}
                    onChange={(e) => payloadHandler(payload, e, 'status', (updatePayload) => {
                        setPayload(updatePayload);
                        if(errors) {
                            setErrors(null);
                        }
                    })}
                />

                <SaveButton 
                    loading={loading}
                    submit={() => submitUpdateUser()}
                />
            </Card.Section>
        </Card>
    )
}