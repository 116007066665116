import { RegionCreate } from "./entry/RegionCreate";
import { RegionUpdate } from "./entry/RegionUpdate";
import { RegionList } from "./view/RegionList";

export const useRegionRoutes = [
    {
        path: "region",
        element: <RegionList />
    },
    {
        path: "region/new",
        element: <RegionCreate />
    },
    {
        path: "region/:id",
        element: <RegionUpdate />
    },
];