import { Button, Card, Divider, FileInput, Grid, MultiSelect, SegmentedControl, Text, Select, TextInput, Image, Group, Modal } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useCallback, useEffect, useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { delRequest, getReqeust, postRequest, putRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { endpoints } from "../../../config/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { useTourPackagePayloads } from "../useTourPackagePayloads";
import { BASE_URL, IMAGE_URL } from "../../../config/environment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const TourPackageUpdate = () => {
    useDocumentTitle("Update Tour Package");

    const segmentCtrl = [
        { label: "Intro", value: "intro" },
        { label: "Attraction", value: "attraction" },
        { label: "By Interest", value: "by_interest" },
        { label: "Hotels", value: "hotels" },
        { label: "Event", value: "event" },
        { label: "Activities", value: "activities" },
        { label: "Season", value: "season" },
        { label: "Explore", value: "explore" }
    ];

    const [payload, setPayload] = useState(useTourPackagePayloads.update);
    const [segment, setSegment] = useState(segmentCtrl[0].value);
    const [destinationType, setDestinationType] = useState([]);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [interests, setInterest] = useState([]);
    const [intro, setIntro] = useState("");
    const [event, setEvent] = useState("");
    const [activities, setActivities] = useState("");
    const [hotels, setHotels] = useState("");
    const [byInterest, setByInterest] = useState("");
    const [attraction, setAttraction] = useState("");
    const [season, setSeason] = useState("");
    const [explore, setExplore] = useState("");
    const [isOpenModel, setIsOpenModel] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    /** Upload Cover Photo */
    const uploadFileHandler = async (e, field) => {
        setLoading(true);
        setErrors(null);
    
        const formData = new FormData();
        formData.append('file', e);
        formData.append('method', 'PUT');
    
        const response = await postRequest("file/upload", formData);
    
        setLoading(true);
        setErrors(null);
    
        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Upload image process",
                message: response.message,
                status: 'success'
            }));
    
            payloadHandler(payload, response.data.name, field, (updateValue) => {
                setPayload(updateValue);
                setLoading(false);
            });
            return;
        }
    
        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }
    
        dispatch(updateNotification({
            title: "Error: Upload image process",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    }

    /**
     * Delete Tour Package
     * @returns 
     */
    const delTourPackage = async () => {
        const response = await delRequest(`${endpoints.tourPackage}/${params.id}`);

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Delete tour package",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            setIsOpenModel(false);
            navigate("/tour-package");
            return;
        }
    }
    /**
     * Update Destination
     */
    const submitTourPackageUpdate = async () => {
        let reqPayload = payload;
        reqPayload.activities = activities;
        reqPayload.intro = intro;
        reqPayload.attraction = attraction;
        reqPayload.hotels = hotels;
        reqPayload.event = event;
        reqPayload.season = season;
        reqPayload.explore = explore;

        const response = await putRequest(`${endpoints.tourPackage}/${params.id}`, reqPayload);

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Update tour package",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            navigate("/tour-package");
            return;
        }

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        dispatch(updateNotification({
            title: "Error: Upadte tour package",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    }

    /**
     * Loading Interest Data
     */
    const loadingIntersetData = useCallback(async () => {
        setErrors(null);
        setLoading(true);

        const response = await getReqeust(`${endpoints.interest}`);

        if(response && response.status === 200) {
            setLoading(false);

            const interestArray = response.data.map((value) => {
                return {
                    label: value.name,
                    value: value.id
                }
            });

            setInterest(interestArray);
            return;
        }

        dispatch(updateNotification({
            title: "Error: Destination Type",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    },[dispatch, setInterest]);

    /**
     * Loading Destination Data
     */
    const loadingDestinationTypeData = useCallback(async () => {
        setErrors(null);
        setLoading(true);

        const response = await getReqeust(`${endpoints.destinationType}`);

        if(response && response.status === 200) {
            setLoading(false);

            const destinationTypeArray = response.data.map((value) => {
                return {
                    label: value.name,
                    value: value.id
                }
            });

            setDestinationType(destinationTypeArray);
            return;
        }

        dispatch(updateNotification({
            title: "Error: Destination Type",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    },[dispatch, setDestinationType]);

    /**
     * Loading Tour Package Data
     */
    const loadingTourPackageData = useCallback(async () => {
        setErrors(null);
        setLoading(true);

        const response = await getReqeust(`${endpoints.tourPackage}/${params.id}`);

        if(response && response.status === 200) {
            setLoading(false);
            setPayload(response.data);
            setIntro(response.data.intro ? response.data.intro : "");
            setActivities(response.data.activities ? response.data.activities : "");
            setAttraction(response.data.attraction ? response.data.attraction : "");
            setByInterest(response.data.byInterest ? response.data.byInterest : "");
            setHotels(response.data.hotels ? response.data.hotels : "");
            setEvent(response.data.event ? response.data.event : "");
            setSeason(response.data.season ? response.data.season : "");
            setExplore(response.data.explore ? response.data.explore : "");
            return;
        }

        dispatch(updateNotification({
            title: "Error: Tour Package",
            message: response.message,
            status: 'fail'
        }));  
        setLoading(false);
        return;
    },[dispatch, setPayload, params]);

    useEffect(() => {
        loadingIntersetData();
    }, [loadingIntersetData]);

    useEffect(() => {
        loadingDestinationTypeData();
    },[loadingDestinationTypeData]);

    useEffect(() => {
        loadingTourPackageData();
    },[loadingTourPackageData]);

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section px={"sm"}>
                        <Text sx={{ fontSize: 20, fontWeight: 500}}> Update Tour Package </Text>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Image 
                                className={`${payload.cover_photo ? "cover-image" : null}`}
                                src={payload.cover_photo ? `${IMAGE_URL}/${payload.cover_photo}` : null} 
                            />
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <div className="input-group">
                                    <TextInput 
                                        my={"sm"}
                                        placeholder="Enter Tour Package Title"
                                        label="Title"
                                        disabled={loading}
                                        value={payload.title}
                                        withAsterisk
                                        error={errors && errors['title'] && (<FormValidationMessage message={errors['title'][0]} />)}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'title', (updateValue) => {
                                            setPayload(updateValue);
                                        })}
                                    />
                                </div>

                                <div className="input-group">
                                    <Select
                                        my={"sm"}
                                        label="Destination Type"
                                        disabled={loading}
                                        value={payload.destination_type_id}
                                        withAsterisk
                                        data={destinationType ? destinationType : []}
                                        dropdownPosition={"bottom"}
                                        error={errors && errors['destination_type_id'] && <FormValidationMessage message={errors['destination_type_id'][0]}/>}
                                        onChange={(e) => payloadHandler(payload, e, 'destination_type_id', (updatePayload) => {
                                            setPayload(updatePayload);
                                            if(errors) {
                                                setErrors(null);
                                            }
                                        })}
                                    />
                                </div>

                                <div className="input-group">
                                    <FileInput 
                                        my={"sm"}
                                        label="Cover Photo"
                                        accept="image/*"
                                        disabled={loading}
                                        onChange={(e) => uploadFileHandler(e, "cover_photo")}
                                    />
                                </div>
                                
                            </Grid.Col>

                            <Grid.Col sm={12} md={6} lg={6} p={"sm"}>
                                <div className="input-group">
                                    <TextInput 
                                        my={"sm"}
                                        placeholder="Enter Tour Package Subtitle"
                                        label="Sub Title"
                                        disabled={loading}
                                        value={payload.sub_title || ""}
                                        error={errors && errors['sub_title'] && (<FormValidationMessage message={errors['sub_title'][0]} />)}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'sub_title', (updateValue) => {
                                            setPayload(updateValue);
                                        })}
                                    />
                                </div>

                                <div className="input-group">
                                    <MultiSelect 
                                        my={"sm"}
                                        placeholder="Select Interest"
                                        label="Interests"
                                        data={interests}
                                        multiple={true}
                                        searchable={true}
                                        dropdownPosition="bottom"
                                        value={payload.interest_ids}
                                        error={errors && errors['interest_ids'] && (<FormValidationMessage message={errors['interest_ids'][0]} />)}
                                        onChange={(e) => payloadHandler(payload, e, 'interest_ids', (updateValue) => {
                                            setPayload(updateValue);
                                        })}
                                    />
                                </div>

                                <div className="input-group">
                                    <Select
                                        my={"sm"}
                                        label="Status"
                                        disabled={loading}
                                        value={payload.status}
                                        data={[
                                            { value: "ACTIVE", label: "ACTIVE" },
                                            { value: "DISABLE", label: "DISABLE" }
                                        ]}
                                        error={errors && errors['status'] && <FormValidationMessage message={errors['status'][0]}/>}
                                        onChange={(e) => payloadHandler(payload, e, 'status', (updatePayload) => {
                                            setPayload(updatePayload);
                                            if(errors) {
                                                setErrors(null);
                                            }
                                        })}
                                    />
                                </div>
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                <SegmentedControl
                                    sx={{ width: "100%"}}
                                    defaultValue={segment}
                                    data={segmentCtrl}
                                    onChange={(e) => setSegment(e)}
                                />
                            </Grid.Col>

                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                {segment === 'intro' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={intro || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setIntro(editor.getData());
                                        }}
                                    />
                                )}

                                {segment === 'attraction' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={attraction || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setAttraction(editor.getData());
                                        }}
                                    />
                                )}

                                {segment === 'by_interest' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={byInterest || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setByInterest(editor.getData());
                                        }}
                                    />
                                )}

                                {segment === 'hotels' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={hotels || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setHotels(editor.getData());
                                        }}
                                    />
                                )}

                                {segment === 'event' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={event || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setEvent(editor.getData());
                                        }}
                                    />
                                )}

                                {segment === 'activities' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={activities || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setActivities(editor.getData());
                                        }}
                                    />
                                )}

                                {segment === 'season' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={season || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setSeason(editor.getData());
                                        }}
                                    />
                                )}

                                {segment === 'explore' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={explore || ""}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: `${BASE_URL}/api/ckfinder`
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            setExplore(editor.getData());
                                        }}
                                    />
                                )}
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12} p={"sm"}>
                                <Group>
                                    <Button
                                        variant="outline" 
                                        loading={loading}
                                        onClick={() => submitTourPackageUpdate()}
                                    >
                                        Save
                                    </Button>

                                    <Button
                                        variant="outline" 
                                        color="red"
                                        loading={loading}
                                        onClick={() => setIsOpenModel(true)}
                                    >
                                        Delete
                                    </Button>
                                </Group>
                            </Grid.Col>
                        </Grid>

                    </Card.Section>
                </Card>
            </Grid.Col>

            <Modal 
                opened={isOpenModel} 
                withCloseButton 
                onClose={() => setIsOpenModel(false)} 
                title="Delete"
            >
                <Modal.Body> 
                    <p> Are you sure to delete this content?  </p>
                    <Button variant="outline" color="red" onClick={() => delTourPackage()}> OK </Button>
                </Modal.Body>
            </Modal>
        </Grid>
    )
}