import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
    list: [],
    total: 0,
    invoice: null
};

const purchaseOderSlice = createSlice({
    name: "PURCHASE_ORDER",
    initialState,
    reducers: {
        setPurchaseOrder(state, action) {
            if(action.payload) {
                state.list = action.payload.data;
                state.total = action.payload.total;
            }
            return state;
        },
        setPurhaseDetail(state, action) {
            if(action.payload) {
                state.invoice = action.payload;
                return;
            }
        }
    }    
})

export const {
    setPurchaseOrder,
    setPurhaseDetail
} = purchaseOderSlice.actions;

export default purchaseOderSlice.reducer;