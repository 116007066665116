import { Grid } from "@mantine/core"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getReqeust } from "../../../services/apiService";
import { updateNotification } from "../../../redux/notificationSlice";

import { UpdateFaq } from "../entry/UpdateFaq";
import { useDocumentTitle } from "@mantine/hooks";


export const FaqDetail = () => {
    useDocumentTitle("FAQ Detail");
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const params = useParams();
    const dispatch = useDispatch();

    const loadingData = useCallback(async () => {
        setLoading(true);
        const response = await getReqeust(`faq/${params.id}`);

        if (response && (response.status === 401 || response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error",
                message: response.message,
                status: 'fail'
            }));
            setLoading(false);
            return;
        }

        if (response && response.status === 200) {
            setData(response.data);
            setLoading(false);
            return;
        }
    }, [dispatch, params.id]);

    useEffect(() => {
        loadingData();
    }, [loadingData]);

    return(
        <Grid gutter={0}>
            {data && loading === false && (
                <>
                    <Grid.Col sm={12}>
                        <UpdateFaq 
                            dataSource={data} 
                            update={(e) => setData(e)} 
                        />
                    </Grid.Col>  
                </>
            )}
        </Grid>
    )
}