export const useTourPackagePayloads = {
    create : {
        region_ids: "",
        interest_ids: "",
        title: "",
        cover_photo: "",
        feature_photo: "",
        sub_title: "",
        top_desinations: "",
        intro: "",
        attraction: "",
        by_interest: "",
        hotels: "",
        season: "",
        event: "",
        activities: "",
        explore: ""
    },
    update: {
        region_ids: "",
        interest_ids: "",
        title: "",
        cover_photo: "",
        feature_photo: "",
        sub_title: "",
        top_desinations: "",
        intro: "",
        attraction: "",
        by_interest: "",
        hotels: "",
        season: "",
        event: "",
        activities: "",
        explore: "",
        status: ""
    }
}