import { Button, Card, Divider, Modal, Grid, Group, Select, Text, TextInput, Textarea } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useCallback, useEffect, useState } from "react";
import { FormValidationMessage } from "../../../components/FormValidationMessage";
import { delRequest, getReqeust, putRequest } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../redux/notificationSlice";
import { payloadHandler } from "../../../helpers/payloadHandler";
import { useInterestPayloads } from "../useInterestPayloads";
import { endpoints } from "../../../config/endpoints";
import { useNavigate, useParams } from "react-router-dom";

export const InterestUpdate = () => {
    useDocumentTitle("Update Interest");

    const [payload, setPayload] = useState(useInterestPayloads.update);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const delInterest = async () => {
        setLoading(true);
        setErrors(null);

        const response = await delRequest(`${endpoints.interest}/${params.id}`);

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Interest Delete",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Interest Delete",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            navigate(`/${endpoints.interest}`);
            return;
        }

        setLoading(false);
    }


    const submitUpdateInterest = async () => {
        setLoading(true);
        setErrors(null);

        const response = await putRequest(`${endpoints.interest}/${params.id}`, payload);

        if(response && response.errors) {
            setErrors(response.errors);
            setLoading(false);
            return;
        }

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: Interest Update",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            dispatch(updateNotification({
                title: "Interest Update",
                message: response.message,
                status: 'success'
            }));
            setLoading(false);
            return;
        }

        setLoading(false);
    }

    const loadingData = useCallback( async () => {
        setLoading(true);
        setErrors(null);

        const response = await getReqeust(`${endpoints.interest}/${params.id}`);

        if(response && (response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error: interest Detail",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            setPayload(response.data);
            setLoading(false);
            return;
        }

        return;
    }, [dispatch, params]);

    useEffect(() => {
        loadingData();
    },[loadingData]);

    return(
        <Grid gutter={0}>
            <Grid.Col sm={12} md={12} lg={12}>
                <Card p={50} className="card-border">
                    <Card.Section>
                        <Text sx={{ fontSize: 20, fontWeight: 500}}> Update Interest </Text>
                        <Divider variant="dashed" my={"sm"} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter={0}>
                            <Grid.Col sm={12} md={12} lg={12}>
                                <TextInput 
                                    my={"sm"}
                                    placeholder="Enter name"
                                    label="Name"
                                    disabled={loading}
                                    value={payload.name || ""}
                                    error={errors && errors['name'] && (<FormValidationMessage message={errors['name'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'name', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />

                                <Textarea 
                                    my={"sm"}
                                    placeholder="Enter Remark"
                                    label="Remark"
                                    disabled={loading}
                                    value={payload.remark || ""}
                                    error={errors && errors['remark'] && (<FormValidationMessage message={errors['remark'][0]} />)}
                                    onChange={(e) => payloadHandler(payload, e.target.value, 'remark', (updateValue) => {
                                        setPayload(updateValue);
                                        setErrors(null);
                                    })}
                                />

                                <Select
                                    my={"sm"}
                                    label="Status"
                                    disabled={loading}
                                    value={payload.status}
                                    data={[
                                        { value: "ACTIVE", label: "ACTIVE" },
                                        { value: "DISABLE", label: "DISABLE" }
                                    ]}
                                    error={errors && errors['status'] && <FormValidationMessage message={errors['status'][0]}/>}
                                    onChange={(e) => payloadHandler(payload, e, 'status', (updatePayload) => {
                                        setPayload(updatePayload);
                                        if(errors) {
                                            setErrors(null);
                                        }
                                    })}
                                />

                                <Group>
                                    <Button
                                        variant="outline" 
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => submitUpdateInterest()}
                                    >
                                        Save
                                    </Button>

                                    <Button
                                        variant="outline" 
                                        color="red"
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        Delete
                                    </Button>
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Card.Section>
                </Card>
            </Grid.Col>

            <Modal 
                opened={isModalOpen} 
                withCloseButton 
                onClose={() => setIsModalOpen(false)} 
                title="Delete"
            >
                <Modal.Body> 
                    <p> Are you sure to delete this content?  </p>
                    <Button variant="outline" color="red" onClick={() => delInterest()}> OK </Button>
                </Modal.Body>
            </Modal>
        </Grid>
    )
}