import { Grid } from "@mantine/core"
import { useCallback, useEffect, useState } from "react"
import { getReqeust } from "../../../services/apiService";
import { useParams } from "react-router-dom";
import { updateNotification } from "../../../redux/notificationSlice";
import { useDispatch } from "react-redux";
import { UserUpdate } from "../UserUpdate";
import { UserChangePassword } from "../entry/UserChangePassword";

export const UserDetail = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const params = useParams();
    const dispatch = useDispatch();
    
    const loadingData = useCallback(async () => {
        setLoading(true);
        const response = await getReqeust(`user/${params.id}`);

        if(response && (response.status === 401 || response.status === 500 || response.status === 403)) {
            dispatch(updateNotification({
                title: "Error",
                message: response.message,
                status: 'fail'
            }));  
            setLoading(false);
            return;
        }

        if(response && response.status === 200) {
            setData(response.data);
            setLoading(false);
            return;
        }
    },[dispatch, params.id])

    useEffect(() => {
        loadingData();
    },[loadingData])

    return(
        <Grid gutter={0}>
            {loading === false && (
                <>
                    <Grid.Col sm={12} md={6} sx={{ padding: "5px"}}>
                        <UserUpdate 
                            dataSource={data} 
                            update={(e) => setData(e)} 
                        />
                    </Grid.Col>

                    <Grid.Col sm={12} md={6} sx={{padding: "5px"}}>
                        <UserChangePassword />
                    </Grid.Col>    
                </>
            )}
        </Grid>
    )
}