import { TourPackageCreate } from "./entry/TourPackageCreate";
import { TourPackageUpdate } from "./entry/TourPackageUpdate";
import { TourPackageList } from "./view/TourPackageList";

export const useTourPackageRoutes = [
    {
        path: "tour-package",
        element: <TourPackageList />
    },
    {
        path: "tour-package/new",
        element: <TourPackageCreate />
    },
    {
        path: "tour-package/:id",
        element: <TourPackageUpdate />
    }
];