import { CreateFaq } from "./entry/CreateFaq";
import { FaqDetail } from "./view/FaqDetail";
import { FaqList } from "./view/FaqList";

export const useFaqRoutes = [
    {
        path: "faq",
        children: [
            {
                path: "",
                element: <FaqList />,
            },
            {
                path: "new",
                element: <CreateFaq />,
            },
            {
                path: ":id",
                element: <FaqDetail />
            }
        ],
    },
];
