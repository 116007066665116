import { Button } from "@mantine/core"
import { margin } from "../config/layout"
import { primary } from "../config/color"

export const PrimaryBtn = ({loading, submit, label}) => {

    return(
        <Button
            my={margin}
            fullWidth
            variant="filled"
            onClick={() => submit()}
            loading={loading}
            disabled={loading}
            color={primary}
        > 
        { label }
    </Button>
    )
} 